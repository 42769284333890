/* eslint-disable no-console */
import axios, { AxiosResponse, AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';
import { getCookie } from '../utils/browser';

const REQ_LOGGING = false;
const RES_LOGGING = true;

const IS_DEV = process.env.ENV !== 'production';
const IS_BROWSER = typeof window !== 'undefined';

let token: string | null = null;

export const getToken = async (): Promise<string | null> => {
    if (IS_BROWSER) {
        if (token) {
            return token;
        }
        token = await getCookie('token');

        return token;
    }

    return null;
};

export const clearCachedToken = (): void => {
    token = null;
};

const api = axios.create({
    // baseURL: IS_DEV ? `http://localhost:${port}/api/` : '/api/',
    baseURL: '/api/',
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(
    async config => {
        if (IS_DEV && REQ_LOGGING) console.info('✉️ ', config);

        if (IS_BROWSER) {
            const bearerToken = await getToken();
            if (bearerToken) {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `bearer ${bearerToken}`;
            }
        }

        return config;
    },
    error => {
        if (IS_DEV && REQ_LOGGING) console.error('✉️ ', error);
        Sentry.captureException(error);
        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    (response: AxiosResponse) => {
        if (IS_DEV && RES_LOGGING) console.info('📨 ', response);

        return response;
    },
    (error: AxiosError) => {
        if (error.response && error.response.status === 403) {
            if (error.response.config.url !== '/user_profile' && !IS_DEV) {
                try {
                    window.location.href = '/login?redirect=compete';
                } catch (e) {
                    console.error('Service error', e);
                }
            }
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: error.response.data.error, status: error.response.status });
        }
        if (error.response && error.response.status === 500) {
            if (IS_DEV && RES_LOGGING) console.error('500! Check API');
            Sentry.captureException(error);

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: 'Server Error!', status: 500 });
        }

        if (IS_DEV && RES_LOGGING) console.error('📨 ', { error });

        if (error.response) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({ message: error.response.data.error, status: error.response.status });
        }
        return Promise.reject(error);
    },
);

export default api;
