/**
 * Creates new cookie or removes cookie with negative expiration
 * @param  {string} key       The key or identifier for the store
 * @param  {string} value     Contents of the store
 * @returns {void}
 */
// import { parseCookies, setCookie as setNookie, destroyCookie } from 'nookies';
import { parseCookies, setCookie as setNookie } from 'nookies';

export const setCookie = (key: string, value: string | null): void => {
    const expires: Date = new Date('31 Dec 2999');

    // --- Set a negative expiry to delete cookie
    // if (value === null) {
    //     destroyCookie(null, key);
    //     return;
    // }

    const { hostname } = window.location;
    setNookie(null, key, value || '', {
        expires,
        secure: process.env.ENV === 'production',
        domain: hostname,
        path: '/',
    });
};

export const getCookie = (key: string): string => {
    const cookies = parseCookies();
    return cookies[key];
};
